<style lang="scss" scoped>
	.wrap {
		/deep/ .el-table__expanded-cell{
			padding: 0;
		}
		.match-info {
			font-size: 13px;
			padding: 10px;
			background: #fff;
			border-bottom: 1px solid #f0f0f0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.time {
				color: #9b9b9b;
			}
			.name {
				font-weight: bold;
				font-size: 14px;
				margin-top: 3px;
			}
		}
		h4 {
			padding: 5px 10px;
		}
	}
</style>

<template>
	<div class="wrap">
		<div class="match-info">
			<div class="info">
				SR | {{ matchInfo.sourceSportsName || matchInfo.thirdSport }} |
				{{ matchInfo.sourceRegionName || matchInfo.thirdRegion }} |
				{{ matchInfo.sourceTournamentName || matchInfo.thirdTournament }} |
				<span class="time">{{ matchInfo.endTime | formatTime }}</span> <br />
				<div class="name">
					{{  matchInfo.name || matchInfo.sourceTournamentName || matchInfo.thirdTournament }}
					{{ matchInfo.year }}
				</div>
			</div>
		</div>
		<h4>
			SR Outright Market Mapping
		</h4>
		<el-table
			:data="list"
			v-loading="loading"
			@sort-change="sort"
			@expand-change="getOutrightOptionsList"
			:max-height="tableHeight -100"
      size="mini"
			border=""
		>
			<el-table-column label="" type="expand" width="30">
				<template v-slot="{row}">
					<el-table :data="row.options || []" size="mini" border="">
						<el-table-column label="" width="30"></el-table-column>
						<el-table-column label="Market Name">
							<template v-slot="{$index}">
								<div v-if="$index === 0">
									<span class="name" v-if="row.language">
										{{  matchInfo.name || matchInfo.sourceTournamentName || matchInfo.thirdTournament }}
										{{ matchInfo.year }}
									</span>
									<span>
										{{row.language && row.language['ENG'] || ''}}
									</span>
									<span style="color: #999">ID: {{ row.languageId }}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column
							label="Option Name"
							width="260px"
						>
							<template v-slot="{row}">
								<span>
									{{row.language && row.language['ENG'] || ''}}
								</span>
								<span style="color: #999">ID: {{ row.languageId }}</span>
							</template>
						</el-table-column>
						<el-table-column label="" width="130px"></el-table-column>
						<el-table-column label="" width="110px"></el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<el-table-column label="SR">
				<template v-slot="{ row }"> 
					<span class="name" v-if="row.language">
					{{  matchInfo.name || matchInfo.sourceTournamentName || matchInfo.thirdTournament }}
					{{ matchInfo.year }}
				</span>
				<span>
					{{row.language && row.language['ENG'] || ''}}
				</span>
				<span style="color: #999">ID: {{ row.languageId }}</span>
				</template>
			</el-table-column>
			<el-table-column label="Standard" width="260px">
				<template v-slot="{ row }">
					<el-select :disabled="row.status === 1" size="mini" v-model="row.marketType" placeholder="" @focus="getOutrightOptions(row)" @change="updateOutrightMarketType(row)">
						<el-option v-for="item in marketList" :key="item.code" :label="item.value" :value="item.code" ></el-option>
					</el-select>
				</template>
			</el-table-column>
			<el-table-column label="Market Status" width="130px">
				<template v-slot="{ row }">
					{{ marketStatusObj[row.marketStatus] }}
				</template>
			</el-table-column>
			<el-table-column
				label="Push"
				align="center"
				prop="shortName"
				width="110px"
			>
				<template v-slot="{ row }">
					<el-switch
						v-model="row.status"
						:active-value="1"
						:inactive-value="0"
						@change="changeStatus(row)"
					></el-switch>
				</template>
			</el-table-column>
		</el-table>
		    <el-row type="flex" justify="end" style="margin-top: 20px">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>
		<el-dialog
			width="1200px"
			:visible.sync="dialog.show"
      @closed="getList"
			:close-on-click-modal="dialog.clickModal"
		>
      <el-row type="flex" align="middle" slot="title">
        <h3>Language Detail</h3>  &nbsp; &nbsp; <el-button type="text" @click="editFlag = !editFlag">Edit</el-button>
      </el-row>
			<el-table border="" size="mini" height="400" :data="dialog.list" :span-method="arraySpanMethod">
				<el-table-column width="70" label="">
					<template v-slot="{ $index}">
					 {{$index === 0 ? 'Market' : 'Option'}}
					</template>
				</el-table-column>
				<el-table-column label="ID">
					<template v-slot="{row}">{{row.nameCode | lid}}</template>
				</el-table-column>
				<el-table-column v-for="item in langData" :key="item" :label="item">
					<template v-slot="{ row: {language} }">
            <el-input v-model="language[item]" placeholder="Input" size="mini" v-if="editFlag"></el-input>
            <span v-else>{{ language[item] }}</span>
					</template>
				</el-table-column>
			</el-table>
      <div slot="footer">
        <el-button type="info" @click="dialogHide">Cancel</el-button>
        <el-button type="success" v-if="editFlag" @click="comfirm(1)">Save</el-button>
        <el-button type="success" v-else @click="comfirm(0)">Comfirm</el-button>
      </div>
		</el-dialog>
	</div>
</template>

<script>
import {
	getOutrightMarkets as getData,
	getOutrightOptions,
	getOutrightOptionsList,
  updateOutrightMarketType,
	getOutrightLanguageDetail,
	updateOutrightMarketStatus,
	batchEditInternational
} from '@/service/subscribe'
import TableMixin from '@/mixins/subscibe/table'
import {marketStatusObj, langData} from '@/utils/constants'
let cloneLangData = []
export default {
	name: 'MarketMapping',
	mixins: [TableMixin],
	data() {
		return {
			extraData: {
				flag: false,
				status: '',
				dataSourceCode: ''
			},
      editFlag: false,
			marketList: [],
			langData: langData,
      marketStatusObj
		}
	},
	computed: {
		matchId() {
			return this.$route.query.matchId
		},
		matchInfo() {
			return JSON.parse(this.$route.query.info || '{}')
		}
	},
	filters: {
		lid(txt = '') {
			if(txt) {
				return txt.split('-')[1]
			}
		}
	},
	methods: {
		updateOutrightMarketStatus(row) {
			updateOutrightMarketStatus({marketId: row.id, status: row.status}).then(res => {
				if(res.code === 0) {
					this.$message.success('Success')
				}
			}).finally(e => {
				this.getList()
			})
		},
		getOutrightOptionsList(row) {
			getOutrightOptionsList({marketId: row.id}).then(res => {
				if(res.code === 0) {
					this.$set(row, 'options', res.data)
				}
			})
		},
    updateOutrightMarketType(row) {
      const {id: marketId, marketType} =row
      updateOutrightMarketType({marketId,marketType}).then(res => {
      }).finally(e => {
				this.getList()
			})
    },
    comfirm(isSave) {
      if(isSave) {
				cloneLangData
			 	const list =	this.dialog.list.filter((item, index) => {
					 const oldData = cloneLangData[index].language
					 let result = false
					 for(let key in item.language) {
						 if(item.language[key] !== oldData[key]) {
							 result = true
						 }
					 }
					 return result
				})
				if(list.length === 0) {
					return this.editFlag = false
				}
				batchEditInternational(list).then(res => {
					this.editFlag = false
				})
        
      } else {
				this.updateOutrightMarketStatus(this.dialogForm)
        this.dialogHide()
      }
    },
		arraySpanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0 && rowIndex > 0) {
				if (rowIndex === 1) {
					return {
						rowspan: this.dialog.list.length -1,
						colspan: 1
					}
				} else {
					return {
						rowspan: 0,
						colspan: 0
					}
				}
			}
		},
		changeStatus(row) {
			if (row.status) {
				this.preview(row)
			} else {
				this.$confirm('', 'Are you sure to release market mapping? ').then(res => {
					this.updateOutrightMarketStatus(row)
				}).finally(e => {
					this.getList()
				})
			}
		},
		preview(row = {}) {
      this.editFlag = false
			getOutrightLanguageDetail({id: row.id}).then(res => {
				if(res.code === 0) {
					if(res.data) {
						const {'14': outrightMarket,'15': outrightOption} = res.data
						this.dialog.list = [
							...outrightMarket,
							...outrightOption
						]
					} else {
						this.dialog.list = []
					}
					cloneLangData = JSON.parse(JSON.stringify(this.dialog.list))
					const langObj = cloneLangData[0].language
					this.langData = this.langData.filter(item => item in langObj)
					this.dialogEdit(row)
				}
			}).catch(e => {
				console.log(e)
				this.getList()
			})
		},
		getData,
		getOutrightOptions(row) {
			getOutrightOptions({ standardSportId: this.matchInfo.standardSportId || 1 }).then(res => {
				if (res.code === 0) {
					this.marketList = res.data
				}
			})
		},
		getList() {
			this.getData({ sourceMatchId: this.matchId, ...this.queryData }).then(res => {
				if (res.code === 0) {
					const data = res.data.records || []
					this.total = res.data.total
					data.forEach(item => {
						item.options = []
					})
					this.list = data
					if(this.marketList.length === 0 && this.list.length > 0) {
						this.getOutrightOptions(this.list[0])
					}
				}
			})
		}
	},
	created() {
		this.queryData.size = 20
	},
	mounted() {
	}
}
</script>
